import React from "react"

const BreadCrumb = ({ section, sectionURL, pageName }) => (
  <div class="breadcrumb">
    <div class="container">
      <span class="pageLink"><a class="pageURL" href="/">Home</a></span>
      { section ? <span class="pageLink"><a class="pageURL" href={sectionURL}>{section}</a></span> : null }
      <span class="pageTitle">{pageName}</span>
    </div>
  </div>
)

export default BreadCrumb