import styled from "@emotion/styled"

export const Container = styled.div`
  flex: 33%;
  max-width: 33%;
  padding: 15px;

  display: flex;
  flex-direction: column;
  color: black;

  span {
    color: #777777;
  }

  a {
    text-decoration: none;
    color:black;
  }

  img {
    width: 300px;
    height: 215px;
    object-fit: cover;
    border-radius: 6px;
  }

  @media screen and (max-width: 800px) {
    flex: 50%;
    max-width: 50%;
  }

  @media screen and (max-width: 480px) {
    flex: 100%;
    max-width: 100%;

    img {
      width: 100%;
      max-height: 215px;
    }
  }
`

export const ImgContainer = styled.div`
  display: block;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  background: #e0e0e0;
  margin-bottom: 24px;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: auto;
`
