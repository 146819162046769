import React from "react"

import { Container, ImgContainer, Image } from "./article.css"

const Article = ({ data, createdDate }) => (
  <Container className="Article">
    <a href={`/articles/${data.slug}`}>
      <img src={data.picture} />
      <div class="articleText">
        <h3 class="articleTitle">{data.title}</h3>
      </div>
    </a>
  </Container>
)

export default Article
