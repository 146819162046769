import styled from "@emotion/styled"

export const ArticlesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const NavContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;

  li{
    list-style-type: none;
    margin: 0 1vw;
  }

  a{
    text-decoration: none;
    color: black;
    padding: 1vw;
  }

  @media only screen and (max-width: 640px) {
    flex-wrap: wrap;

    li{
      margin: 0;
      flex: 33.33%;
      max-width: calc(33.33% - 20px);
      margin: 10px 10px;
      text-align: center;
      background: #ffffff24;
      padding: 3px;
      border-radius: 4px;
    }
    a{
      display: block;
    }
  }

`
